/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    box2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.95.4a1 1 0 01.8-.4h8.5a1 1 0 01.8.4l2.85 3.8a.5.5 0 01.1.3V15a1 1 0 01-1 1H1a1 1 0 01-1-1V4.5a.5.5 0 01.1-.3zM7.5 1H3.75L1.5 4h6zm1 0v3h6l-2.25-3zM15 5H1v10h14z"/>',
    },
});
